var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", _vm.abilityRequired)
    ? _c(
        "div",
        [
          _c(
            "b-button",
            {
              attrs: { variant: "primary", size: "lg" },
              on: { click: _vm.launchElearning }
            },
            [_vm._v(" Launch E-Learning Site ")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }